import React, { useContext, useState, useEffect } from "react";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
import AuthContext from "../../contexts/auth";
import api from "../../services/config.url";
import showPopup from "../../components/PopUp";
import { Select } from "antd";

const Raffle = (props) => {
  const { setLoading, user } = useContext(AuthContext);
  const [pageSize, setPageSize] = useState(300);
  const [items, setItems] = useState([]);
  const [numResults, setNumResults] = useState("1");
  const [getAll, setGetAll] = useState("true");
  const [randomizedData, setRandomizedData] = useState([]);
  const [randomizedSkus, setRandomizedSkus] = useState(''); // nome corrigido
  const [selectRestoreCad, setSelectRestoreCad] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    index();
    fetchDropdownRestore();
  }, [pageSize, setLoading, getAll, randomizedSkus]);


  const index = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/customers/raffles?current=1&pageSize=${pageSize}&getAll=${getAll}`
      );
      setItems(response.data.records);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao listar os cadernos sorteados";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchRandomizer = async () => {
    try {
      setLoading(true);
      // Converter para número caso necessário
      const quantity = Number(numResults);
      const response = await api.get(
        `/customers/raffles/randomize-numbers?randomizeQtd=${quantity}`
      );
      setRandomizedData(response.data.items);
      setRandomizedSkus(response.data.skus);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao sortear os cadernos";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchDropdownRestore = async () => {
    try {
      const list = await api.get(
        `/customers/raffles/dropdown-restore`
      );

      const dropDown = list.data.map((x) => ({
        label: `${x.product.sku}`,
        value: JSON.stringify(x),
      }));

      setFilteredData(dropDown);
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao carregar a lista de produtos";
      showPopup(BackendError || genericError, "error");
    }
  };

  const handleDownloadCloud = async (randomizedSkus) => {

    if (!randomizedSkus) {
      return showPopup("Não existem cadernos sorteados para download", "error");
    }
    
    const skusArr = randomizedSkus.split(";").map(sku => sku.trim());

    const { name, secondName, document: documentCustomer } = user || {};
    

  if (!name) {
    return showPopup(
      "O campo Nome é obrigatório! Acesse seu perfil para preenchê-lo.",
      "error"
    );
  }
  if (!secondName) {
    return showPopup(
      "O campo Sobrenome é obrigatório! Acesse seu perfil para preenchê-lo.",
      "error"
    );
  }
  if (!documentCustomer) {
    return showPopup(
      "O campo CPF é obrigatório! Acesse seu perfil para preenchê-lo.",
      "error"
    );
  }

 for await (const sku of skusArr) {
  try {
    setLoading(true);

    showPopup(
      `Baixando caderno ${sku}`,
      "success"
    )
    
    const response = await api.post(
      `/customers/products/cloud-pdf`,
      {
        sku,
        customerName: `${name} ${secondName}`,
        customerCPF: documentCustomer,
      },
      { "Content-Type": "application/pdf" }
    );
    const pdfUrl = response.data.downloadUrl;
    fetch(pdfUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao baixar o arquivo");
        }
        return response.blob();
      })
      .then((blob) => {
        saveAs(blob, `${response.data.fileName}.pdf`);
      })
      .catch((error) => {
        console.error("Erro:", error);
      });
  } catch (error) {
    const BackendError = error?.response?.data?.message;
    const genericError = "Erro ao baixar os arquivos";
    showPopup(BackendError || genericError, "error");
  } finally {
    setLoading(false);
  }
 }
  };

  const handleRegisterHistory = async () => {

    const productIds = randomizedData.map(data => data._id);

    const body = {
      products: productIds
    }
    try {
      setLoading(true);
       await api.patch(`/customers/raffles/save-on-history`, body);
       showPopup('Histórico salvo com sucesso', 'success');
    } catch (error) {
      const BackendError = error?.response?.data?.message;
      const genericError = "Erro ao listar os cadernos sorteados";
      showPopup(BackendError || genericError, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveProduct = async (item) => {
    try {
      setLoading(true);
       await api.delete(`/customers/raffles/${item._id}`);
       showPopup('Item removido da lista', 'success');
    } catch (error) {
      console.log(error)
      const BackendError = error?.response?.data?.message ?? '';
      const genericError = "Erro ao remover o item da lista";
      showPopup(BackendError || genericError, "error");
    } finally {
      await index()
      await fetchDropdownRestore();
      setLoading(false);
    }
  }

  const handleRestoreCad = async () => {
    // Converte o valor selecionado de string para objeto
    const item = JSON.parse(selectRestoreCad);
    try {
      setLoading(true);
      await api.patch(`/customers/raffles/restore-cad/${item._id}`);
      showPopup('Caderno restaurado com sucesso', 'success');
    } catch (error) {
      console.log(error);
      const BackendError = error?.response?.data?.message ?? '';
      const genericError = "Erro ao restaurar o caderno";
      showPopup(BackendError || genericError, "error");
    } finally {
      await index();
      await fetchDropdownRestore();
      setSelectRestoreCad(null); // Limpa o <Select> ao confirmar
      setLoading(false);
    }
  };

  const handleSelectChange = (option) => {
    setSelectRestoreCad(option);
  };

  return (
    <div style={{ marginBottom: "100px" }}>
      <CAccordion className="mt-5 m-b100">
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>SORTEIO DE CADERNOS</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <div className="content-raffle">
              <div className="first-content-raffle mb-3">
                <div>
                  <span>N° de resultados:</span>
                  <input
                    type="number"
                    onChange={(e) => setNumResults(e.target.value)}
                    value={numResults}
                    style={{ width: "50px", textAlign: "center" }}
                    maxLength={3}
                    min={0}
                  />
                </div>
                <div>
                  <button className="btn btn-primary" onClick={fetchRandomizer}>
                    Sortear
                  </button>
                </div>
              </div>
              <div className="mb-3 responsive-checkbox">
                <div className="base-flex m-r20">
                  <span>Sortear a lista completa:</span>
                  <input 
                    type="radio" 
                    name="sort"
                    checked={getAll === "true"}
                    onChange={() => setGetAll("true")}
                  />
                </div>
                <div className="base-flex">
                  <span>Sortear apenas CAD's não sorteados:</span>
                  <input 
                    type="radio" 
                    name="sort"
                    checked={getAll === "false"}
                    onChange={() => setGetAll("false")}
                  />
                </div>
              </div>
              <div className="mb-3 base-flex">
                <span>Número(s) sorteado(s):</span>
                {/* Alterado para type="text" */}
                <input 
                  type="text" 
                  value={randomizedSkus}  
                  disabled 
                  style={{ width: "50%" }}
                />
              </div>
              <div className="m-t20">
                <button 
                onClick={() => handleDownloadCloud(randomizedSkus) }
                className={`btn btn-primary mb-3 ${!randomizedSkus ? 'disabled' : ''}`}
                >
                  Baixar o(s) CAD(s) sorteado(s)
                </button>
              </div>
              <div>
                <button 
                 className={`btn btn-primary mb-3 ${!randomizedSkus ? 'disabled' : ''}`}
                 style={{ textAlign: "left"}}
                 onClick={() => handleRegisterHistory(randomizedData) }
                 >
                  Registrar o estudo do(s) CAD(s) no HISTÓRICO
                </button>
              </div>
            </div>
          
            <CAccordion className="mt-5 m-b100">
              <CAccordionItem itemKey={1}>
                <CAccordionHeader>
                  <strong>CADERNOS A SEREM SORTEADOS</strong>
                </CAccordionHeader>
                <CAccordionBody>
                  <div className="restore-cad base-flex">
                    <span className="m-r10">Reincluir o seguinte CAD na lista:</span>
                     {/* <input className="m-r10" type="text" onChange={(e) => setSearch(e.target.value)} /> */}
                     <Select
                      showSearch
                      placeholder="Selecione o caderno"
                      className="m-r10 select-restore-cad"
                      optionFilterProp="children"
                      onChange={handleSelectChange}
                      options={filteredData}
                      value={selectRestoreCad} // Componente controlado
                    />
                    {selectRestoreCad &&
                      <button className="btn btn-primary" onClick={handleRestoreCad}>
                        Confirmar
                      </button>
                    }
                  </div>
                  <div className="mt-5">
                    {items?.length > 0 ? (
                      items.map((item, index) => (
                        <div key={index} className="container-raffle-list">
                          <div className="m-r10">
                            <span>{item.product.sku} - </span>
                            <span>{item.product.name}</span>
                          </div>
                          <div className="raffle-btns">
                          { item?.wasRaffled &&
                            <p className="ico-btn-check m-r5">Ok</p>
                          }
                            <button 
                            className="btn ico-btn-close m-r5"
                            onClick={() => handleRemoveProduct(item) }
                            >X</button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>Não há cadernos para sorteados</div>
                    )}
                  </div>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </div>
  );
};

export default Raffle;
